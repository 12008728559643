<template>
  <c-tab
    type="horizon"
    :dense="true"
    :inlineLabel="true"
    :tabItems.sync="tabItems"
    :height="tabHeight"
    v-model="tab"
  >
    <template v-slot:default="tab">
      <component
        :is="tab.component"
        :param="popupParam"
        :preStartupCheck.sync="preStartupCheck"
        :imprTabInfo.sync="imprTabInfo"
        :research.sync="research"
        :contentHeight.sync="contentHeight"
        @closePopup="closePopup"
        @setRegInfo="setRegInfo"
      />
    </template>
  </c-tab>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'pre-startup-review-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',
        psrCheckStatusCd: '',
        isSearch: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'base',
      tabItems: [
        // 가동전점검 계획/결과 정보
        { name: 'base', icon: 'foundation', label: 'LBL0002108', component: () => import(`${'./preStartupReviewInfo.vue'}`) },
        { name: 'traningScenario', icon: 'account_tree', label: 'Punch list', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`) },
      ],
      preStartupCheck: {
        sopPrestartupCheckId: '',  // 가동전점검 일련 번호
        plantCd: null,  // 사업장코드
        checkName: '',  // 점검명
        checkPurpose: '',  // 점검 목적
        psrEquipmentStatusCd: null,  // 설비 상태
        checkDeptCd: null,  // 점검부서
        sopMocId: '',  // MOC 일련번호
        checkCancarrierId: '',  // 점검 책임자
        checkResultTotal: '',  // 점검결과 종합
        psrCheckStatusCd: '',  // 진행 단계
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        deleteEquipments: [],
      },
      research: {
        impr: '',
        item: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 45) + 'px';
    },
    imprTabInfo() {
      return {
        key: this.popupParam.sopPrestartupCheckId, // 
        ibmTaskTypeCd: 'ITT0000030',
        ibmTaskUnderTypeCd: 'ITTU000045',
        requestContents: this.preStartupCheck.checkName,
        disabled: (this.preStartupCheck.psrCheckStatusCd === 'PCSC000005'),
        isOld: Boolean(this.popupParam.sopPrestartupCheckId),
        gridItem: {
          listUrl: selectConfig.sop.psr.checkItemImprove.list.url,
          param: {
            sopPrestartupCheckId: this.popupParam.sopPrestartupCheckId,
          },
          title: 'LBL0002132', // 점검 결과 별 개선사항 목록
          merge: [
            { index: 0, colName: 'equipmentName' },
            { index: 1, colName: 'mergeCheck1' },
            // { index: 2, colName: 'mergeCheck2' },
            // { index: 3, colName: 'mergeCheck2' },
            // { index: 4, colName: 'mergeCheck2' },
            // { index: 5, colName: 'mergeCheck2' },
            // { index: 11, colName: 'mergeCheck2' },
          ],
          columns: [
            {
              name: 'equipmentName', 
              field: 'equipmentName',
              label: 'LBLEQUIP',
              align: 'center',
              style: 'width:120px',
              sortable: false,
            },
            {
              name: 'psrCheckTypeName', 
              field: 'psrCheckTypeName',
              // 점검유형
              label: 'LBL0002122',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'checkItemName',
              field: 'checkItemName',
              // 점검항목
              label: 'LBL0002124',
              sortable: false,
              child: [
                {
                  name: 'level',
                  field: 'level',
                  // 대분류/중분류/소분류
                  label: 'LBL0002133',
                  align: 'left',
                  style: 'width:400px',
                  sortable: false,
                },
              ]
            },
            {
              name: 'psrCheckPeriodName',
              field: 'psrCheckPeriodName',
              // 점검시기
              label: 'LBL0002128',
              align: 'center',
              style: 'width:45px',
              sortable: false,
            },
            {
              name: 'judgmentResultFlagName',
              field: 'judgmentResultFlagName',
              // 적합여부
              label: 'LBL0002129',
              align: 'center',
              style: 'width:45px',
              sortable: false,
            },
            {
              name: 'checkResult',
              field: 'checkResult',
              // 점검결과
              label: 'LBL0002130',
              align: 'left',
              style: 'width:200px',
              sortable: false,
            },
          ],
          research: this.research,
        },
        gridOther: {
          title: 'LBL0001424', // 항목외 개선 목록
        },
      }
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    closePopup() {
      this.$emit('closePopup')
    },
    setRegInfo(data) {
      this.$emit('setRegInfo', data)
    }
  }
};
</script>
